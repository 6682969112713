// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

.text-color {
  &__green {
    color: $green;
    text-decoration: none;
  }
}

.myName {
  display: none;
}

.myEmail {
  display: none;
}
